import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <div className="row">
        <div className="col-lg-9 mt-5 mb-5 team-opleidingen">
          <SEO title="404: Pagina niet gevonden" />
          <h1 className="mb-3">404: Pagina niet gevonden</h1>
          <p>Er liep iets fout, deze pagina werd niet gevonden. Je kan terugkeren naar de <Link to="/">homepagina</Link> of dit probleem melden via de <Link to="/contact">contactpagina</Link>.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
